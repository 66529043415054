<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑产品"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 8 }"
        :wrapper-col="{ span: 15 }"
        @submit="handleSubmit"
      >
        <a-form-item label="产品名称">
          <a-input
            v-decorator="['name', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入产品名称' },
                { max: 100, message: '最多100个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="型号">
          <a-input
            v-decorator="['product_model', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入型号' },
                { max: 30, message: '最多30个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="项目代码">
          <a-input
            v-decorator="['code', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入项目代码' },
                { max: 20, message: '最多20个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="拼音码">
          <a-input
            v-decorator="['pinyin', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入拼音码' },
                { max: 20, message: '最多20个字符' },
              ]
            }]"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="类型">
          <a-select
            v-decorator="['product_type']"
            :loading="typeOptions.length === 0"
            allow-clear
            @change="getType"
          >
            <a-select-option
              v-for="type in typeOptions"
              :key="type.slug"
              :value="type.slug"
            >
              {{ type.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="文明棺" v-if=" record.product_type_slug === 'coffin' ">
          <a-checkbox
            :checked="is_civilized_coffin"
            @change="getChecked"
          />
        </a-form-item>
        <a-form-item label="遗体SPA" v-if=" record.product_type_slug === 'derivation' ">
          <a-checkbox
            :checked="is_spa"
            @change="getSpaChecked"
          />
        </a-form-item>
        <a-form-item label="销售范围">
          <a-select
            placeholder="请选择销售范围"
            allow-clear
            mode="multiple"
            v-decorator="['sales_scope']"
          >
            <a-select-option key="0" value="order">
              订购产品
            </a-select-option>
            <a-select-option key="1" value="other_items">
              其他项目
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="惠民赠送">
          <a-select
            placeholder="请选择是否惠民赠送"
            allow-clear
            v-decorator="['benefit_the_people',{
              rules: [{ default: 'false'}],
            }]"
          >
            <a-select-option key="true" value="true">
              是
            </a-select-option>
            <a-select-option key="false" value="false">
              否
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="产生任务">
          <a-select
            placeholder="请选择产生任务"
            allow-clear
            mode="multiple"
            v-decorator="['tasks']"
          >
            <a-select-option key="0" value="distribution">
              配货任务
            </a-select-option>
            <a-select-option key="1" value="dispatch">
              花房派工
            </a-select-option>
            <a-select-option key="2" value="arrangement">
              布置灵堂
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="销售渠道">
          <a-select
              placeholder="请选择销售渠道"
              allow-clear
              mode="multiple"
              v-decorator="['sales_channel', {
              rules: [
                { required: true, message: '请选择销售渠道' },
              ]
            }]"
          >
            <a-select-option
                v-for="sale in saleChannelOptions"
                :key="sale.slug"
                :value="sale.slug"
            >
              {{ sale.name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="是否回传到穿衣间">
          <a-checkbox
            v-decorator="['to_dress_room', { valuePropName: 'checked' }]"
          />
        </a-form-item>
        <a-form-item label="产品图片">
          <div
            style="line-height: 20px;margin-top: 10px;margin-bottom: 10px"
          >上传的第一张图默认为产品缩略图，支持png/jpg图片格式，建议尺寸宽高比180:180px，最多10张
          </div>
          <upload-image
            :is-add-watermark="isAddWatermark"
            accept=".jpg,.jpeg,.png"
            :max-count="10"
            :multiple="true"
            @change="handleUploadImage"
            :default-file-list="defaultFileList"
            v-decorator="['attachments',{
              rules:[
                {validator: checkAttachments}
              ]
            }]"
          />
        </a-form-item>
        <a-form-item label="产品介绍">
          <a-textarea
            v-decorator="['remark', {
              normalize: this.$lodash.trim,
              rules: [
                { max: 300, message: '最多300个字符' },
              ]
            }]"
          />
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import UploadImage from '@/components/Upload/Image'
import {getProductSaleChannel, getProductType} from '@/api/product_type'
import { updateTenantProduct } from '@/api/product'

export default {
  name: 'EditTenantProduct',
  components: {
    UploadImage
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    record: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'tenant_product' }),
      typeOptions: [],
      submitting: false,
      isAddWatermark: false,
      attachments: [], // 图片
      defaultFileList: [],
      is_civilized_coffin: false,
      is_spa: false,
      data: {},
      saleChannelOptions: []
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  created() {
    this.getTypeOptions()
    this.getSaleChannelOptions()
    this.initFormData()
  },
  methods: {
    initFormData() {
      this.defaultFileList = []
      this.data = Object.assign({}, {
        name: this.record.name,
        product_model: this.record.product_model,
        product_type: this.record.product_type_slug,
        sales_scope: this.record.sales_scope,
        sales_channel: this.record.sales_channel,
        benefit_the_people:
        this.record.benefit_the_people,
        tasks: this.record.tasks,
        remark: this.record.remark,
        code: this.record.code,
        pinyin: this.record.pinyin,
        to_dress_room: this.record.to_dress_room
      })
      if (this.data.sales_scope == null) {
        this.data.sales_scope = undefined
      }
      if (this.data.tasks == null) {
        this.data.tasks = undefined
      }
      if (this.record.images != null && this.record.images.length > 0) {
        this.record.images.forEach((item, index) => {
          this.defaultFileList.push({
            uid: index,
            name: 'image.png',
            status: 'done',
            url: item.url,
            response: item.response
          })
        })
      }
      this.is_civilized_coffin = this.record.is_civilized_coffin
      this.is_spa = this.record.is_spa
      this.$nextTick(() => {
        this.form.setFieldsValue(this.data)
        this.attachments = this.defaultFileList
      })
    },

    handleUploadImage(fileList) {
      this.attachments = fileList
    },

    // 检查照片
    checkAttachments(rule, value, callback) {
      for (const key in this.attachments) {
        if (this.attachments[key] && this.attachments[key].status === 'error') {
          // eslint-disable-next-line standard/no-callback-literal
          callback('照片上传错误')
          return
        }
      }

      callback()
    },
    getTypeOptions() {
      getProductType().then((res) => {
        if (res.code === '0') {
          this.typeOptions = res.data
        }
      })
    },
    getSaleChannelOptions() {
      getProductSaleChannel().then((res) => {
        if (res.code === '0') {
          this.saleChannelOptions = res.data
        }
      })
    },
    getChecked(e) {
      this.is_civilized_coffin = e.target.checked
    },
    getSpaChecked(e) {
      this.is_spa = e.target.checked
    },
    getType(e) {
      this.record.product_type_slug = e
    },
    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      if (this.$lodash.find(this.attachments, { status: 'uploading' })) {
        this.$warning({
          title: '文件尚未上传完成',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const editData = {
            ...values,
            is_civilized_coffin: this.is_civilized_coffin,
            is_spa: this.is_spa,
            attachments: this.$lodash.map(this.attachments, 'response')
          }
          updateTenantProduct(this.record.id, editData).then((res) => {
            if (res.code === 0) {
              // 关闭模态框
              this.isShow = false
              // 告知父组件已完成
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
