var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"编辑产品"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 8 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"产品名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入产品名称' },
              { max: 100, message: '最多100个字符' } ]
          }]),expression:"['name', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入产品名称' },\n              { max: 100, message: '最多100个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"型号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['product_model', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入型号' },
              { max: 30, message: '最多30个字符' } ]
          }]),expression:"['product_model', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入型号' },\n              { max: 30, message: '最多30个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"项目代码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['code', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入项目代码' },
              { max: 20, message: '最多20个字符' } ]
          }]),expression:"['code', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入项目代码' },\n              { max: 20, message: '最多20个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"拼音码"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['pinyin', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入拼音码' },
              { max: 20, message: '最多20个字符' } ]
          }]),expression:"['pinyin', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入拼音码' },\n              { max: 20, message: '最多20个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['product_type']),expression:"['product_type']"}],attrs:{"loading":_vm.typeOptions.length === 0,"allow-clear":""},on:{"change":_vm.getType}},_vm._l((_vm.typeOptions),function(type){return _c('a-select-option',{key:type.slug,attrs:{"value":type.slug}},[_vm._v(" "+_vm._s(type.name)+" ")])}),1)],1),( _vm.record.product_type_slug === 'coffin' )?_c('a-form-item',{attrs:{"label":"文明棺"}},[_c('a-checkbox',{attrs:{"checked":_vm.is_civilized_coffin},on:{"change":_vm.getChecked}})],1):_vm._e(),( _vm.record.product_type_slug === 'derivation' )?_c('a-form-item',{attrs:{"label":"遗体SPA"}},[_c('a-checkbox',{attrs:{"checked":_vm.is_spa},on:{"change":_vm.getSpaChecked}})],1):_vm._e(),_c('a-form-item',{attrs:{"label":"销售范围"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sales_scope']),expression:"['sales_scope']"}],attrs:{"placeholder":"请选择销售范围","allow-clear":"","mode":"multiple"}},[_c('a-select-option',{key:"0",attrs:{"value":"order"}},[_vm._v(" 订购产品 ")]),_c('a-select-option',{key:"1",attrs:{"value":"other_items"}},[_vm._v(" 其他项目 ")])],1)],1),_c('a-form-item',{attrs:{"label":"惠民赠送"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['benefit_the_people',{
            rules: [{ default: 'false'}],
          }]),expression:"['benefit_the_people',{\n            rules: [{ default: 'false'}],\n          }]"}],attrs:{"placeholder":"请选择是否惠民赠送","allow-clear":""}},[_c('a-select-option',{key:"true",attrs:{"value":"true"}},[_vm._v(" 是 ")]),_c('a-select-option',{key:"false",attrs:{"value":"false"}},[_vm._v(" 否 ")])],1)],1),_c('a-form-item',{attrs:{"label":"产生任务"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['tasks']),expression:"['tasks']"}],attrs:{"placeholder":"请选择产生任务","allow-clear":"","mode":"multiple"}},[_c('a-select-option',{key:"0",attrs:{"value":"distribution"}},[_vm._v(" 配货任务 ")]),_c('a-select-option',{key:"1",attrs:{"value":"dispatch"}},[_vm._v(" 花房派工 ")]),_c('a-select-option',{key:"2",attrs:{"value":"arrangement"}},[_vm._v(" 布置灵堂 ")])],1)],1),_c('a-form-item',{attrs:{"label":"销售渠道"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['sales_channel', {
            rules: [
              { required: true, message: '请选择销售渠道' } ]
          }]),expression:"['sales_channel', {\n            rules: [\n              { required: true, message: '请选择销售渠道' },\n            ]\n          }]"}],attrs:{"placeholder":"请选择销售渠道","allow-clear":"","mode":"multiple"}},_vm._l((_vm.saleChannelOptions),function(sale){return _c('a-select-option',{key:sale.slug,attrs:{"value":sale.slug}},[_vm._v(" "+_vm._s(sale.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"是否回传到穿衣间"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['to_dress_room', { valuePropName: 'checked' }]),expression:"['to_dress_room', { valuePropName: 'checked' }]"}]})],1),_c('a-form-item',{attrs:{"label":"产品图片"}},[_c('div',{staticStyle:{"line-height":"20px","margin-top":"10px","margin-bottom":"10px"}},[_vm._v("上传的第一张图默认为产品缩略图，支持png/jpg图片格式，建议尺寸宽高比180:180px，最多10张 ")]),_c('upload-image',{directives:[{name:"decorator",rawName:"v-decorator",value:(['attachments',{
            rules:[
              {validator: _vm.checkAttachments}
            ]
          }]),expression:"['attachments',{\n            rules:[\n              {validator: checkAttachments}\n            ]\n          }]"}],attrs:{"is-add-watermark":_vm.isAddWatermark,"accept":".jpg,.jpeg,.png","max-count":10,"multiple":true,"default-file-list":_vm.defaultFileList},on:{"change":_vm.handleUploadImage}})],1),_c('a-form-item',{attrs:{"label":"产品介绍"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:(['remark', {
            normalize: this.$lodash.trim,
            rules: [
              { max: 300, message: '最多300个字符' } ]
          }]),expression:"['remark', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { max: 300, message: '最多300个字符' },\n            ]\n          }]"}]})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }